<template>
  <div>
    <b-card text-variant="left" class="card card-congratulations">
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />

    <div class="text-center">
      <h1 class="mb-2 mt-50 text-black">
        Welcome {{ user?.name }}
      </h1>

      <template v-if="parseInt(user?.is_super_admin) === 1">
        <b-card-text class="w-75 mx-auto text-black lead custom-black-color">
          Easily set up your software APIs right now! Follow the 
          <span class="font-weight-1000"> 
            <router-link class="setup-color-black" :to="{ path: 'user/api' }">Set Up Guide</router-link>
          </span> 
          for API installation tutorials and start searching for leads!
        </b-card-text>

        <div class="d-flex justify-content-center mt-3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
            :to="{ path: 'user/api' }"
          >
            <feather-icon
              icon="SettingsIcon"
              class="mr-50"
            />
            <span class="align-middle">Setup Guide</span>
          </b-button>
        </div>
      </template>
    </div>
  </b-card>

    <b-row>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="success"
          :statistic="totalCredits"
          statistic-title="Total Credit Left"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="totalLeads"
          statistic-title="Total Leads"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          :statistic="totalCampaigns"
          statistic-title="Total Campaigns"
        />
      </b-col>

      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="warning"
          :statistic="leadsContacted"
          statistic-title="Leads Contacted"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card md="12" lg="7" sm="12">
          <b-row>
            <b-col> <h4 style="margin-top: 10px">Recent Leads</h4></b-col>
            <b-col class="text-right"
              ><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                style="margin-bottom: 10px"
                @click="goAllLeads"
              >
                View All
              </b-button></b-col
            >
          </b-row>

          <b-card-text class="w-100 text-center">
            <b-table responsive="sm" :items="recentLeads" />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="12" lg="5" sm="12">
        <b-card>
          <b-row>
            <b-col> <h4 style="margin-top: 10px">Recent Campaigns</h4></b-col>
            <b-col class="text-right"
              ><b-button
                style="margin-bottom: 10px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                to="/admin/campaigns"
              >
                View All
              </b-button></b-col
            >
          </b-row>
          <b-card-text class="w-100 text-center">
            <b-table
              responsive="sm"
              :items="recentCampaigns"
              :fields="fields"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BCardText,
  BImg,
  BButton,
  BTable,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "AdminDashboardView",
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BCardText,
    BImg,
    BButton,
    StatisticCardVertical,
    StatisticCardHorizontal,
    BTable,
  },
  computed: {
    ...mapGetters({
      user: "userModule/getUser",
      permissions: "userModule/getPermissions",
    }),
    avatar() {
      return require("@/assets/images/avatars/avatar.webp");
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "leads",
          label: "Leads",
        },
        {
          key: "created_at",
          label: "Created On",
        },
      ],
      // user: {
      //   name: "",
      //   is_super_admin: false
      // },
      items: [],
      recentLeads: [],
      recentCampaigns: [],
      totalLeads: "",
      totalCampaigns: "",
      leadsContacted: "",
      totalCredits: "",
    };
  },
  mounted() {
    this.getRecentCampaigns();
    this.getRecentLeads();
    this.getRecentCredits();

  },
  methods: {

    goAllLeads() {
      this.$router.push({
        name: "all-leads-info",
      });
    },
    getRecentLeads() {
      this.$api.get("/api/leads/recent").then((response) => {
        this.recentLeads = response.data?.data;
        this.leadsContacted = response?.data?.contacted_leads;
        this.totalLeads = response?.data?.total_leads;
      });
    },
    getRecentCampaigns() {
      this.$api.get("/api/campaigns/recent").then((response) => {
        response.data?.data.map((element, index) => {
          this.items.push({
            name: element.name,
            leads: element.lead_info_count,
            created_at: moment(element.created_at).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          });
        });
        this.recentCampaigns = this.items;
        this.totalCampaigns = response?.data?.total;
      });
    },
    getRecentCredits() {
      this.$api.get("/api/user/credits").then((response) => {
        this.totalCredits = response.data?.data;
        
      });
    }
  },
};
</script>

<style>
.bg-primary2 {
  background-color: #009d5c !important;
}
.custom-black-color {
  color: black !important;
}
.font-weight-1000 {
  font-weight: 1000;
}
.setup-color-black {
  color: black !important;
}
</style>
